import {Button} from "./Button";
import {useTranslation} from "react-i18next";

export function NotFoundPageContent(){
    const {t} = useTranslation();
    return(
        <div className="flex items-center justify-center py-10 px-2 flex-col text-dark-black">
            <h1 className="text-8xl font-medium">404</h1>
            <p className="text-lg mt-5">{t('notFoundPage.text')}</p>
            <Button link="/" buttonStyle="green" className="w-40 mt-10">{t('notFoundPage.button')}</Button>
        </div>
    )
}