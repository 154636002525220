import React from "react";
import {getInputClassName} from "./getInputClassName";

interface InputContainerProps{
    select?:boolean
    name:string
    label?:string
    children:React.ReactNode
    focus:boolean
    valid:boolean
    disabled?:boolean
    shake?:boolean
}

export function InputContainer(props:InputContainerProps){
    const inputClassName = "rounded-3 w-full border text-xs min-h-7.5 flex transition relative font-normal"
    const fullInputClassName = getInputClassName(inputClassName,props.valid,props.focus,props.disabled,props.shake,props.select)

    let labelClassName = 'block text-xs'
    if(!props.valid && !props.focus) labelClassName += ' text-red-500'
    if(props.disabled) labelClassName += ' text-gray-400'

    return(
        <>
            {(props.label && props.label.length>0) &&
                <>{props.name === ''?
                    <div className={labelClassName}>{props.label}</div>
                    :
                    <label className={labelClassName} htmlFor={'inp_' + props.name}>{props.label}</label>
                }</>
            }
            <div className={fullInputClassName} id={'inp_' + props.name+'_container'}>
                {props.children}
            </div>
        </>
    )
}