import React, {useContext, useEffect, useState} from "react";
import {AdminContainer} from "../../containers/AdminPageContainer";
import {Button} from "../../components/ui/Button";
import {Api} from "../../components/Api";
import {DeleteIco,EditIco} from "../../components/admin/AdminIcons";
import {AdminConfirm} from "../../components/admin/modal/AdminConfirm";
import {AdminMessage} from "../../components/admin/modal/AdminMessage";
import {InputsStuff} from "../../components/admin/InputStuff";
import {LocalizationContext} from "../../context/LocalizationContext";
import {useTranslation} from "react-i18next";
import "../../components/ckContent.scss"

export function AdminProjectsPage(){
    const [adminMessage,setAdminMessage] = useState('')
    const [openAdminMessage,setOpenAdminMessage] = useState(false)

    const {language} = useContext(LocalizationContext)
    const {t} = useTranslation();

    const [editorOpen,setEditorOpen] = useState(false)
    const [listInit,setListInit] = useState(false)
    const [projects,setProjects] = useState<{[key:string]:any}[]>([])
    const [targetProjectId,setTargetProjectId] = useState('')
    const [projectData,setProjectData] = useState<{[key:string]:any}>({})
    const [confirmDelete,setConfirmDelete] = useState(false)
    const [confirmSave,setConfirmSave] = useState(false)
    const [confirmClose,setConfirmClose] = useState(false)

    const refreshProjects = ()=>{
        Api({
            method:'get',
            url:'projects',
            data:{},
            callback:(data)=>{
                if(data.items){
                    setProjects(data.items)
                }
            }
        }).then()
    }
    if(!listInit){
        refreshProjects()
        setListInit(true)
    }

    const addProject = ()=>{
        Api({
            method:'post',
            url:'project/add',
            auth:true,
            data:{
                img:'',
                nameText:{uk:'Новий проєкт'},
                shortAboutText:{},
                mainContent:{}
            },
            callback:()=>{
                refreshProjects()
            }
        }).then()
    }

    const deleteProject = () => {
        Api({
            method:'post',
            url:'project/remove',
            auth:true,
            data:{
                _id:targetProjectId
            },
            callback:()=>{
                setConfirmDelete(false)
                setAdminMessage('Проєкт успішно видалено')
                setOpenAdminMessage(true)
                refreshProjects()
            }
        }).then()
    }

    const projectsItems = projects.map((item,number)=>{

        const handleDelete = ()=>{
            setTargetProjectId(item._id)
            setConfirmDelete(true)
        }

        const openEditor = ()=>{
            setTargetProjectId(item._id)
            setProjectData({})
            setEditorOpen(true)
        }

        return (
            <div
                key={number}
                className="flex justify-between border p-2 items-center mb-2 rounded-3 border-yellow-bg"
            >
                <p className="text-lg w-1/2">{item.nameText.uk}</p>
                <p className="text w-1/2">{item.category && t('mainPage.'+item.category)}</p>
                <div className="flex">
                    <button className="mx-2 block" tabIndex={-1} aria-label="Редагувати" onClick={openEditor}>
                        <EditIco color="white"/>
                    </button>
                    <button className="block" tabIndex={-1} aria-label="Видалити" onClick={handleDelete}>
                        <DeleteIco color="white"/>
                    </button>
                </div>
            </div>
        )
    })

    const handleCloseProjectEditor = ()=>{
        setConfirmClose(true)
    }
    const closeProjectEditor = ()=>{
        setEditorOpen(false)
        setConfirmClose(false)
        refreshProjects()
    }



    const textEditSchema:{[key:string]:any} = {
        img:{
            type:'file',
            fileType:'image',
            label:'Зображення для списку',
            uploadText:'Завантажити зображення'
        },
        nameText:{
            type:'input',
            label:'Назва проєкту'
        },
        shortAboutText:{
            type:'textarea',
            label:'Короткий опис проєкту'
        },
        mainContent: {
            label:'Основний контент',
            type:'contentEditor'
        }
    }

    useEffect(()=>{
        if(Object.keys(projectData).length === 0 && editorOpen){
            Api({
                method:'get',
                data: {id:targetProjectId},
                url:'project',
                callback:(data:any)=>{
                    if (data.item){
                        let projData = data.item
                        Object.keys(projData).forEach(key=>{
                            try{
                                projData[key] = JSON.parse(projData[key])
                            }catch (e){}
                        })
                        setProjectData(projData)
                    }
                }
            }).then()
        }
    },[projectData,targetProjectId,editorOpen])

    const handleSaveProject = ()=>{
        setConfirmSave(true)
    }

    const saveProject = ()=>{
        Api({
            method:'post',
            auth:true,
            url:'project',
            data:projectData,
            callback:function (){
                setConfirmSave(false)
                setAdminMessage('Проєкт успішно збережено')
                setOpenAdminMessage(true)
            }
        }).then()
    }

    const inputsStuff = InputsStuff(language,setProjectData,projectData,textEditSchema)

    return(
        <AdminContainer>
            {editorOpen?
                <>
                    <div className="flex w-full justify-between items-center mb-5">
                        <h1 className="text-4xl">Редагування проєкта</h1>
                        <div className="flex">
                            <Button onClick={handleSaveProject} buttonStyle="green">Зберегти</Button>
                            <Button className="ml-2" onClick={handleCloseProjectEditor} buttonStyle="green">Закрити</Button>
                        </div>
                    </div>
                    <div className="flex">
                        {inputsStuff.fileEditInputs}
                    </div>
                    <div className="flex -mx-2">
                        {inputsStuff.textEditInputs}
                    </div>
                    {inputsStuff.textareaInputs}
                    {inputsStuff.contentEditInputs}
                </>
                :
                <>
                    <div className="flex w-full justify-between items-center mb-5">
                        <h1 className="text-4xl">Проєкти</h1>
                        <Button onClick={addProject} buttonStyle="green">Додати проєкт</Button>
                    </div>
                    <div>
                        {projectsItems.length === 0 && <div className="text-center">Список проектів пустий</div>}
                        {projectsItems}
                    </div>
                </>
            }
            <AdminMessage message={adminMessage} open={openAdminMessage} setOpen={setOpenAdminMessage}/>
            {confirmDelete && <AdminConfirm message={'Видалити проєкт?'} confirm={deleteProject} cancel={()=>{setConfirmDelete(false)}}/>}
            {confirmClose && <AdminConfirm message={'Закрити редактор проекту?'} confirm={closeProjectEditor} cancel={()=>{setConfirmClose(false)}}/>}
            {confirmSave && <AdminConfirm message={'Зберегти проект?'} confirm={saveProject} cancel={()=>{setConfirmSave(false)}}/>}
        </AdminContainer>
    )
}