import {FormEvent, useEffect, useState} from "react";
import {Input} from "../../components/ui/inputs/Input";
import {Button} from "../../components/ui/Button";
import {Api} from "../../components/Api";
import {hasToken} from "../../components/admin/functions/hasToken";
import {useNavigate} from 'react-router-dom'

export function AdminLoginPage(){

    const [login,setLogin] = useState('');
    const [loginValid,setLoginValid] = useState(false);
    const [password,setPassword] = useState('');
    const [passwordValid,setPasswordValid] = useState(false);


    const [loading,setLoading] = useState(false);

    const [init,setInit] = useState(false)

    const inputs = {
        login:{
            name: 'login',
            label: 'Login',
            value: login,
            valid: loginValid,
            validate: 'required|string|min:3',
            type: 'text',
            className: 'w-60 my-2',
            updateValue: setLogin,
            updateValid: setLoginValid
        },
        password:{
            name: 'password',
            label: 'Password',
            value: password,
            valid: passwordValid,
            validate: 'required|string|min:3',
            type: 'password',
            className: '',
            updateValue: setPassword,
            updateValid: setPasswordValid

        }
    }


    let navigate = useNavigate();

    useEffect(()=>{
        if(!init){
            const token = hasToken();
            console.log('token',token)
            if(token){
                setInit(true)
                Api({
                    method:'get',
                    url:'auth',
                    auth:true,
                    callback:()=>{
                        navigate('/Admin/panel')
                    },
                    error:()=>{
                        setInit(true)
                    }
                })
            }else{
                setInit(true)
            }
        }
    },[init,navigate])

    const errorAuth = () =>{

        setLogin('')
        setLoginValid(false)
        setPassword('')
        setPasswordValid(false)
    }

    const handleSubmit = (e:FormEvent<HTMLFormElement>)=>{
        e.preventDefault()
        if(loginValid && passwordValid){
            Api({
                method:'post',
                url:'login',
                data:{login:login,password:password},
                start:()=>setLoading(true),
                end:()=>setLoading(false),
                callback:(data)=>{
                    console.log('login Data',data)
                    if(data.token){
                        localStorage.setItem('authToken',data.token)
                        navigate('/Admin/panel')

                    }else{
                        errorAuth();
                    }

                },
                error:errorAuth
            })
        }
    }


    return(
        <div className="flex items-center justify-center fixed inset-0 a-container">
            {init && <form onSubmit={handleSubmit}>
                <Input {...inputs.login}/>
                <Input {...inputs.password}/>
                <Button type="submit" buttonStyle="green" loading={loading} className="w-full mt-5">Enter</Button>
            </form>}
        </div>
    )
}