import React, {useContext} from "react";
import {Select} from "./inputs/Select/Select";
import {LocalizationContext} from "../../context/LocalizationContext";

export function LanguageSelect(){
    const {language, setLanguage} = useContext(LocalizationContext)

    const handleLanguageChange = (newLanguage:string) => {
        setLanguage(newLanguage);
    }

    const inputProps = {
        name: 'botApi',
        value: language,
        options: {uk: 'UA', en: 'EN'},
        updateValue: handleLanguageChange
    }

    return(
        <Select className="w-12" {...inputProps}/>
    )
}