export function getFileType(file: File): Promise<string | undefined> {
    function getFileExtension(file: File): string {
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf('.');

        if (lastDotIndex === -1) return '';

        return fileName.substring(lastDotIndex + 1);
    }

    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const extension = getFileExtension(file).toLocaleLowerCase()
            const view = new Uint8Array(reader.result).subarray(0, 4);
            let fileType: string | undefined;
            if (extension === 'heic') {
                fileType = 'image/heic';
            } else if (view[0] === 0xff && view[1] === 0xd8 && view[2] === 0xff) {
                fileType = 'image/jpeg';
            } else if (view[0] === 0x89 && view[1] === 0x50 && view[2] === 0x4e && view[3] === 0x47) {
                fileType = 'image/png';
            } else if (view[0] === 0x25 && view[1] === 0x50 && view[2] === 0x44 && view[3] === 0x46) {
                fileType = 'application/pdf';
            } else if (view.subarray(4, 8).toString() === [0x66, 0x74, 0x79, 0x70].toString()) {
                fileType = 'video/mp4';
            } else if (view[0] === 0x52 && view[1] === 0x49 && view[2] === 0x46 && view[3] === 0x46 &&
                view[8] === 0x57 && view[9] === 0x45 && view[10] === 0x42 && view[11] === 0x50) {
                fileType = 'image/webp';
            } else if (view[0] === 0x1A && view[1] === 0x45 && view[2] === 0xDF && view[3] === 0xA3) {
                fileType = 'video/webm';
            }

            resolve(fileType);
        };

        reader.readAsArrayBuffer(file.slice(0, 4));
    });
}
