import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {CustomUploadAdapter} from "./CustomUploadAdapter";
import React, {useContext, useEffect, useRef, useState} from "react";
import {LocalizationContext} from "../../../context/LocalizationContext";

interface AdminTextEditorProps{
    label:string
    value:string
    setValue:(value:string)=>void
}

export function AdminTextEditor(props:AdminTextEditorProps){
    const {language} = useContext(LocalizationContext)
    const [uniqId] = useState(new Date().getTime())

    const toolbarContainerRef = useRef<HTMLDivElement | null>(null);

    function CustomUploadAdapterPlugin(editor:any) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader:any) => {
            return new CustomUploadAdapter(loader);
        };
    }
    useEffect(()=>{
        if(toolbarContainerRef.current) {
            toolbarContainerRef.current.innerHTML = '';
        }
    },[language])

    const toolbarId = 'toolbar-'+uniqId;

    return(
        <div className="my-5">
            <p className="mb-2 text-2xl">{props.label}</p>
            <div className="bg-white text-black-900 rounded-3">
                <div ref={toolbarContainerRef} id={toolbarId}/>
                <CKEditor
                    key={language}
                    editor={DecoupledEditor}
                    data={props.value}
                    onReady={editor => {

                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                            return new CustomUploadAdapter(loader);
                        }

                        console.log('Editor is ready to use!', editor)

                        if(toolbarContainerRef.current){

                            if(document.getElementById(toolbarId)?.nextElementSibling?.classList.contains('ck-toolbar')){
                                document.getElementById(toolbarId)?.nextElementSibling?.remove()
                            }
                            if(document.getElementById(toolbarId)?.previousElementSibling?.classList.contains('ck-toolbar')){
                                document.getElementById(toolbarId)?.previousElementSibling?.remove()
                            }

                            toolbarContainerRef.current.appendChild(editor.ui.view.toolbar.element as HTMLDivElement);

                            if((editor.ui.getEditableElement() as HTMLDivElement)){
                                (editor.ui.getEditableElement() as HTMLDivElement).parentElement?.insertBefore(
                                    editor.ui.view.toolbar.element as HTMLDivElement,
                                    editor.ui.getEditableElement() as HTMLDivElement
                                );
                            }
                        }
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        props.setValue(data);
                    }}
                    config={{
                        extraPlugins: [CustomUploadAdapterPlugin],
                    }}
                />
            </div>
        </div>
    )
}