import React from "react";
import {CloseCross, WarningIco} from "../AdminIcons";
import {Button} from "../../ui/Button";

interface AdminConfirmProps{
    message:string
    confirm:()=>void
    cancel:()=>void
}
export function AdminConfirm(props:AdminConfirmProps){
    return(<div className="flex fixed inset-0 z-40 bg-black-900/50 items-center justify-center text-black-900">
        <div className="bg-white p-5 relative w-96" onClick={(e)=>{e.stopPropagation()}}>
            <button tabIndex={-1} aria-label="Закрити" onClick={props.cancel} className="absolute right-2 top-2"><CloseCross/></button>
            <WarningIco className="w-14 h-14 mx-auto"/>
            <p className="text-center my-5">{props.message}</p>
            <div className="flex w-full justify-between">
                <Button onClick={props.confirm} className="mx-auto" buttonStyle="green">Так</Button>
                <Button onClick={props.cancel} className="mx-auto" buttonStyle="green">Ні</Button>
            </div>
        </div>
    </div>)
}