import React, {Fragment} from "react";
import {Input} from "../ui/inputs/Input";
import {AdminFileInput} from "./FileUpload/AdminFileInput";
import {AdminTextEditor} from "./AdminTextEditor/AdminTextEditor";

export function InputsStuff(language:string,setEditorData:(fn:(prev:{[key:string]:any})=>{[key:string]:any})=>void,editorData:{[key:string]:any},textEditSchema:{[key:string]:any}){

    const updateStringDataByKey = (key:string,value:string)=>{
        setEditorData(prevData=>{
            let newData = Object.assign({},prevData)
            if(newData[key]){
                newData[key][language]=value
            }else{
                newData[key]={}
                newData[key][language]=value
            }
            return newData
        })
    }
    const updateFileByKey = (key:string,value:string)=>{
        setEditorData((prev)=>{
            let newData = Object.assign({},prev)
            newData[key] = value
            return newData
        })
    }

    const contentEditInput = (inputKey:string) => Object.keys(textEditSchema).map((key,index)=>{
        const inpType = textEditSchema[inputKey].type
        if(inpType === 'contentEditor' && inputKey === key){
            const value = editorData[inputKey] && editorData[inputKey][language] ? editorData[inputKey][language]:''
            return (<AdminTextEditor
                label={textEditSchema[inputKey].label}
                key={index}
                value={value}
                setValue={(value:string)=>{
                    updateStringDataByKey(inputKey,value)
                }}
            />)
        }else{
            return <Fragment key={index}/>
        }
    })

    const textEditInputs = Object.keys(textEditSchema)?Object.keys(textEditSchema).map((key,index)=>{
        const inpType = textEditSchema[key].type
        if(inpType === 'input'){
            const value = editorData[key] && editorData[key][language]? editorData[key][language]:''
            const inputProps = {
                name:key,
                label:textEditSchema[key].label||key,
                value:value,
                valid:true,
                updateValue:(value:string)=>{
                    updateStringDataByKey(key,value)
                },
                type:'text'
            }
            return (<div className="w-full mx-2" key={index}>
                <Input {...inputProps}/>
            </div>)
        }else{
            return <Fragment key={index}/>
        }
    }):[]

    const fileEditInputs = Object.keys(textEditSchema).map((key,index)=>{
        const inpType = textEditSchema[key].type
        if(inpType === 'file'){
            const value = editorData[key] ? editorData[key]:''
            return (<AdminFileInput
                key={index}
                type={textEditSchema[key].fileType}
                value={value}
                label={textEditSchema[key].label}
                afterUploadCallback={(data)=>{
                    updateFileByKey(key,data.filePath)
                }}
                uploadText={textEditSchema[key].uploadText}
            />)
        }else{
            return <Fragment key={index}/>
        }

    })


    const contentEditInputs = Object.keys(textEditSchema).map((key,index)=>{
        const inpType = textEditSchema[key].type
        if(inpType === 'contentEditor'){
            return (contentEditInput(key))
        }else{
            return <Fragment key={index}/>
        }

    })

    const textareaInputs = Object.keys(textEditSchema).map((key,index)=>{
        const inpType = textEditSchema[key].type
        if(inpType === 'textarea'){
            const value = editorData[key] && editorData[key][language]? editorData[key][language]:''
            const inputProps = {
                name:key,
                label:textEditSchema[key].label||key,
                value:value,
                valid:true,
                updateValue:(value:string)=>{
                    updateStringDataByKey(key,value)
                },
                className:'my-5',
                type:'textarea'
            }
            return (<Input key={index} {...inputProps} />)
        }else{
            return <Fragment key={index}/>
        }
    })

    return {
        textEditInputs:textEditInputs,
        fileEditInputs:fileEditInputs,
        contentEditInputs:contentEditInputs,
        textareaInputs:textareaInputs
    }
}