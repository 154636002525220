import {PageContainer} from "../containers/PageContainer";
import {useContext, useEffect, useState} from "react";
import {serverUrl} from "../components/admin/functions/serverUrl";
import {getLangText} from "../getLangText";
import {LocalizationContext} from "../context/LocalizationContext";
import {Api} from "../components/Api";
import {NotFoundPageContent} from "../components/ui/NotFoundPageContent";
import "../components/ckContent.scss"

export function ProjectPage(){
    const {language} = useContext(LocalizationContext)

    const [notFound,setNotFound] = useState(false)

    const [projectData,setProjectData] = useState<{[key:string]:any}>({})

    useEffect(()=>{

        Api({
            method:'get',
            url:'project',
            data:{
                id:document.location.pathname.split('/')[2]
            },
            callback:(data)=>{
                if(data.item){
                    setProjectData(data.item)
                }
            },
            error:()=>{
                setNotFound(true)
            }
        }).then()
    },[])

    return(
        <PageContainer>
            {notFound?
                <NotFoundPageContent/>
                :
                <>
                    {projectData.img && <>
                        <div  className="bg-cover bg-top fixed left-0 z-0 h-project-preview-small w-full" style={{backgroundImage:'url('+serverUrl(projectData.img)+')'}}/>
                        <div
                            className="flex flex-col h-project-preview-small justify-center relative cursor-pointer text-center p-2"

                        >
                            <div className="group-hover:opacity-25 bg-dark-900/50 absolute inset-0 z-10"/>
                            <h1 className="text-2xl font-medium relative z-20">{getLangText(projectData,language,'nameText')}</h1>
                            <p className="text-lg mt-5 relative z-20">{getLangText(projectData,language,'shortAboutText')}</p>
                        </div>
                    </>}
                    <div className="p-2 relative z-20 bg-dark-900">
                        <div className="ck-content" dangerouslySetInnerHTML={{__html:projectData.mainContent && projectData.mainContent[language]}}/>
                    </div>
                </>
            }
        </PageContainer>
    )
}