export function getLangText(data:{[key:string]:any},lang:string,key:string){

    const defLang = "uk"

    let textData = ""

    if(data[key]){
        if(typeof data[key] === 'string'){
            textData = data[key]
        }else{
            if(data[key][lang]){
                textData = data[key][lang]
            }else if(data[key][defLang]){
                textData = data[key][defLang]
            }
        }
    }

    return textData

}