import React, {useState} from "react";
import {AdminContainer} from "../../containers/AdminPageContainer";
import {Button} from "../../components/ui/Button";
import {FileIco,ProjectIco} from "../../components/admin/AdminIcons";
import {AdminConfirm} from "../../components/admin/modal/AdminConfirm";
import {AdminMessage} from "../../components/admin/modal/AdminMessage";
import {Api} from "../../components/Api";

export function AdminPanelPage(){

    const [adminMessage,setAdminMessage] = useState('')
    const [openAdminMessage,setOpenAdminMessage] = useState(false)

    const [clearFilesConfirm,setClearFilesConfirm] = useState(false)

    const handleClearFiles = ()=>{
        setClearFilesConfirm(true)
    }

    const clearFiles = ()=>{

        Api({
            method:'post',
            auth:true,
            data: {},
            url:'clearFiles',
            callback:()=>{
                setClearFilesConfirm(false)
                setAdminMessage('Невикористовувані файли успішно видалено')
                setOpenAdminMessage(true)
            }
        }).then()

    }

    return(
        <AdminContainer>
            <div className="flex items-center justify-center">
                <Button link={'/Admin/projects'} buttonStyle={'tile'} className="m-2"><ProjectIco className="block mb-3 h-7 w-7" color="white"/>Проєкти</Button>
                <Button onClick={handleClearFiles} buttonStyle={'tile'} className="m-2"><FileIco className="block mb-3 h-7 w-7" color="white"/>Очистити файли</Button>
            </div>
            {clearFilesConfirm && <AdminConfirm message={'Видалити невикористовувані файли?'} confirm={clearFiles} cancel={()=>{setClearFilesConfirm(false)}}/>}
            <AdminMessage message={adminMessage} open={openAdminMessage} setOpen={setOpenAdminMessage}/>
        </AdminContainer>
    )
}
