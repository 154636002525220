import React from "react";

interface InputIconsContainerProps{
    children:React.ReactNode
}

export function InputIconsContainer(props:InputIconsContainerProps){
    return(
        <div className="items-center h-7 flex">
            {props.children}
        </div>
    )
}