import React, {useEffect} from "react";
import {hasToken} from "../components/admin/functions/hasToken";
import {useNavigate} from "react-router-dom";
import {Api} from "../components/Api";
import {AdminMessageState} from "../context/admin/AdminMessageContext";
import {AdminMessage} from "../components/admin/modal/AdminMessage";
import {Header} from "../components/ui/Header";
import "../components/admin/admin.scss"

interface AdminContainerProps{
    children:React.ReactNode
}

export function AdminContainer(props:AdminContainerProps){
    const token = hasToken()

    let navigate = useNavigate()

    if(!token){
        navigate('/Admin')
    }

    useEffect(()=>{
        Api({
            method:'get',
            url:'auth',
            auth:true,
            error:()=>{
                navigate('/Admin')
            }
        }).then()
    },[navigate])

    return(
        <AdminMessageState>
            <Header login={true}/>
            <div className="px-2 py-5 a-container">
                {props.children}
            </div>
            <AdminMessage/>
        </AdminMessageState>
    )
}