import axios, {AxiosRequestConfig}  from "axios";

interface ApiProps{
    method:string
    url:string
    auth?:boolean
    data?: { [key: string]:any }
    callback?:(data:any)=>void
    error?:(err:any)=>void
    start?:()=>void
    end?:()=>void
}

export async function Api(props:ApiProps){

    let axiosConfig: AxiosRequestConfig = {}
    const apiUrl = "/api/"

    if(props.auth){
        axiosConfig.headers = {'Authorization':'Bearer '+localStorage.getItem('authToken')}
    }

    if (typeof props.start === 'function') {
        props.start();
    }

    const errorCallback = (err:any)=>{
        if (props.error && typeof props.error === 'function') {
            props.error(err)
        }
    }

    try {
        const url = apiUrl+props.url;
        if (props.method === 'post') {
            const response = await axios.post(url, props.data, Object.assign({withCredentials: true},axiosConfig))
            props.callback && props.callback(response.data);
        } else if (props.method === 'get') {
            let params = '';
            if (props.data) Object.keys(props.data).forEach(key => {
                if (props.data && typeof props.data[key] === 'string') params += "&" + key + "=" + props.data[key]
            })
            params = params.substring(1)
            const response = await axios.get(params.length > 0 ? url + '?' + params : url,axiosConfig)
            props.callback && props.callback(response.data);
        } else if (props.method === 'del') {
            const response = await axios.delete(url,axiosConfig)
            props.callback && props.callback(response.data);
        } else if (props.method === 'put') {
            const response = await axios.put(url, props.data,axiosConfig)
            props.callback && props.callback(response.data);
        }
        props.end && props.end();
    } catch (err:any) {
        props.end && props.end();
        if(err.response && err.response.status){
            if(err.response.status === 403 && props.auth){
                localStorage.setItem('authToken','')
            }else{
                errorCallback(err)
            }
        }else{
            errorCallback(err)
        }
    }
}