import React, {useEffect,createContext, useState} from "react";
import i18n from "i18next";
import {I18nextProvider} from "react-i18next";

interface ILocalizationContext {
    language: string,
    setLanguage: (str: string) => void
}

export const LocalizationContext = createContext<ILocalizationContext>({
    language: '',
    setLanguage: () => {
    }
})

export const LocalizationState = ({children}: { children: React.ReactNode }) => {

    const [language, setLanguage] = useState(localStorage.getItem('language') || navigator.language.split(/[-_]/)[0]);

    useEffect(()=>{
        localStorage.setItem('language', language);
        i18n.changeLanguage(language).then()
    },[language])

    return (
        <I18nextProvider i18n={i18n}>
            <LocalizationContext.Provider value={{language, setLanguage}}>
                {children}
            </LocalizationContext.Provider>
        </I18nextProvider>
    )
}