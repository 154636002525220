import React, {useState, useRef} from "react"
import Select, { SelectInstance }  from 'react-select';
import './Select.scss'
import {ISelect} from "../inputsModels";
import {ArrowDownIco} from "../inputsIcons";
import {InvalidIcon} from "../inputsIcons";
import {InputContainer} from "../InputContainer/InputContainer";
import {Validation} from "../Validation";
import {InputIconsContainer} from "../InputContainer/InputIconsContainer";


function SelectField(props: ISelect){
    const [valid,setValid] = useState(Validation(props.value, props.validate))
    const [focus,setFocus] = useState(false)

    const selectRef = useRef<SelectInstance>(null);

    const validation = (value:string) =>{
        let validData = Validation(value, props.validate);
        props.updateValid && props.updateValid(validData)
        setValid(validData);
    }

    const changeHandler = (newValue: any) => {
        props.updateValue && props.updateValue(newValue.value)
        if(newValue.value !== ''){
            props.onBlur && props.onBlur()
            validation(newValue.value)
        }
    }

    const focusHandler = () => {
        setFocus(true)
    }

    const blurHandler = () => {
        setFocus(false)
        if(props.value !== ''){
            validation(props.value)
            props.onBlur && props.onBlur()
        }
    }

    const optionsList = Object.keys(props.options).map(key => {
        if(props.icons){
            return {value: key, label: props.options[key],icon:props.icons[key]}
        }else{
            return {value: key, label: props.options[key]}
        }
    })

    const arrowDownIcoClassName = !focus ? 'mr-1' : 'absolute right-1 z-0'

    const clickInputIcon = (e:MouseEvent) => {
        e.stopPropagation()
        if (selectRef.current) {
            if(focus){
                selectRef.current.blur();
            }else{
                selectRef.current.focus();
            }
        }
    }

    const inputFull = <>
        {props.loading?
            <div className="r-select h-7 w-input -mx-1p bg-transparent flex items-center px-1">Завантаження...</div>
            :
            <Select
                className="r-select h-7 w-input -mx-1p bg-transparent min-w-0"
                classNamePrefix={props.style ? "r-select-" + props.style : "r-select"}
                value={{value: props.value, label: props.options[props.value]}}
                options={optionsList}
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                isSearchable={false}
                isDisabled={props.disabled}
                aria-label={props.label}
                ref={selectRef}
                openMenuOnFocus={true}
                inputId={'inp_'+props.name}
                menuPlacement={props.menuPlacement}
            />
        }
        <InputIconsContainer>
            {(!valid && !focus) && <InvalidIcon className="w-5 -ml-6 mr-1" onClick={clickInputIcon}/>}
            <ArrowDownIco className={arrowDownIcoClassName} onClick={clickInputIcon} color="white"/>
        </InputIconsContainer>
    </>

    return (
        <div className={props.className}>
            <InputContainer
                select={true}
                name={props.loading?'':props.name}
                label={props.label}
                focus={focus}
                valid={valid}
                disabled={props.disabled}
                shake={props.shake}
            >
                {inputFull}
            </InputContainer>
        </div>
    )
}

export {SelectField as Select}