import React from "react";
import {Route, Routes} from "react-router-dom";
import {NotFoundPage} from "../pages/NotFoundPage";
import {HomePage} from "../pages/HomePage";
import {AdminLoginPage} from "../pages/admin/AdminLoginPage";
import {AdminPanelPage} from "../pages/admin/AdminPanelPage";
import {AdminProjectsPage} from "../pages/admin/AdminProjectsPage";
import {ProjectPage} from "../pages/ProjectPage";

export function Router(){
    return(
        <Routes>
            <Route path='/Admin' element={<AdminLoginPage/>}/>
            <Route path='/Admin/panel' element={<AdminPanelPage/>}/>
            <Route path='/Admin/projects' element={<AdminProjectsPage/>}/>

            <Route path='/projects/:projectId' element={<ProjectPage/>}/>
            <Route path='' element={<HomePage/>}/>
            <Route path='/' element={<HomePage/>}/>
            <Route path='*' element={<NotFoundPage/>}/>
        </Routes>
    )
}