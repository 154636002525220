import React, {useContext, useState} from "react";
import {Api} from "../../Api";
import {AdminMessageContext} from "../../../context/admin/AdminMessageContext";
import {getFileType} from "../functions/getFileType";
import {WarningIco} from "../AdminIcons";

interface FileUploaderProps{
    children:React.ReactNode
    type:string
    afterUploadCallback?:(data:any)=>void
    stepIteration?:(data:any,callback?:(data:any)=>void)=>void
    manyFiles?:boolean
    folderId?:string
}
export function FileUploader(props:FileUploaderProps){

    const {setOpen,setMessage} = useContext(AdminMessageContext)
    const [manyUploadStatusOpen,setManyUploadStatusOpen] = useState(false)
    const [manyUploadStatusText,setManyUploadStatusText] = useState('')



    const callback = (data:any)=>{
        if(props.afterUploadCallback){
            props.afterUploadCallback(data);
        }
    }

    const uploadFile = async (file?:File,type?:string,uploadCallback?:(data:any)=>void) => {

        const valid = await checkFile(file,type)
        if(valid && file){
            const formData = new FormData();
            formData.append('file', file);

            Api({
                method: 'post',
                auth:true,
                data:formData,
                url: 'upload',
                callback: uploadCallback
            }).then()

        }else{
            setManyUploadStatusOpen(false)
            const fileTypes = type === 'image'?['image/jpeg','image/png','image/webp','image/heic']:type === 'video'?['video/mp4','video/webm']:[]
            setMessage('Файл має відповідати одному з типів: '+fileTypes.join(', '))
            setOpen(true)
        }
    }

    const checkFile = async (file?:File,type?:string) => {
        let valid = false
        if(file){
            const fileTypes = type === 'image'?['image/jpeg','image/png','image/webp','image/heic']:type === 'video'?['video/mp4','video/webm']:[]
            const fileType = await getFileType(file);
            if((fileType && fileTypes.includes(fileType)) || type === 'video'){
                valid = true
            }
        }
        return valid
    }

    const uploadArrayOfFiles = async (files:FileList,type:string,uploadCallback?:(data:any)=>void,stepIteration?:(data:any,callback?:(data:any)=>void)=>void)=>{

        let index = 0;
        let filePaths:string[] = []

        const filesArray = Array.from(files)

        const uploadOne = async ()=>{
            if(filesArray[index]){
                setManyUploadStatusText((index+1)+'/'+filesArray.length)
                setManyUploadStatusOpen(true)
                await uploadFile(filesArray[index],type,async (data:any)=>{
                    stepIteration && stepIteration(data,async ()=>{
                        data.filePath && filePaths.push(data.filePath)
                        index++
                        await uploadOne()
                    })

                })
            }else{
                setManyUploadStatusOpen(false)
                if(filesArray.length>0){
                    uploadCallback && uploadCallback({filePaths:filePaths})
                }
            }
        }

        await uploadOne()
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if(!props.manyFiles){
            const file = event.target.files?.[0];
            await uploadFile(file,props.type,callback)
        }else if(event.target.files){
            const files = event.target.files;
            if(files){

                await uploadArrayOfFiles(files,props.type,callback,props.stepIteration)

                /*
                let valid = true;
                for (const file of Array.from(files)) {
                    if(valid){
                        valid = await checkFile(file,props.type)
                    }
                }

                if(valid){

                    const formData = new FormData();
                    Array.from(files).forEach((file) => {
                        formData.append('files', file);
                    })

                    Api({
                        method: 'post',
                        auth:true,
                        data:formData,
                        url: 'uploadMany',
                        callback: callback
                    }).then()

                }else{

                    const fileTypes = props.type === 'image'?['image/jpeg','image/png','image/webp']:props.type === 'video'?['video/mp4','video/webm']:[]
                    setMessage('Файл має відповідати одному з типів: '+fileTypes.join(', '))
                    setOpen(true)
                }

                 */
            }
        }
    };

    return (
        <div className="relative w-max overflow-hidden">
            {manyUploadStatusOpen && <div className="flex fixed inset-0 z-40 bg-black-900/50 items-center justify-center">
                <div className="bg-white p-5 relative w-96">
                    <WarningIco className="w-14 h-14 mx-auto"/>
                    <p className="text-center my-5">Завантажую {manyUploadStatusText}</p>
                </div>
            </div>}
            <div className="absolute inset-0 opacity-0">
                {props.manyFiles?
                    <input type="file" onChange={handleFileChange} multiple={true}/>
                    :
                    <input type="file" onChange={handleFileChange}/>
                }
            </div>
            {props.children}
        </div>
    )
}