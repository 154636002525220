import {LanguageSelect} from "./LanguageSelect";
import {Link} from "react-router-dom";
import {LogoutButton} from "../admin/LogoutButton";

interface HeaderProps{
    login?:boolean
}

export function Header(props:HeaderProps){
    const home = props.login?'/Admin/panel':'/'


    return(
        <header className="flex justify-between bg-yellow-bg items-center p-2 sticky z-40 w-full left-0 top-0">
            <Link to={home} className="relative block w-24 h-6 text-center">
                <img
                    className="absolute w-full top-1/2 -translate-y-1/2 z-0"
                    src={process.env.PUBLIC_URL+'/images/logo-small.svg'}
                    alt="Kotila"
                />
                <span className="relative z-10 font-bold uppercase text-3xl tracking-tighter leading-6 h-6 inline-block text-transparent">Kotila</span>
            </Link>

            {props.login?
                <div className="flex items-center">
                    <LanguageSelect/>
                    <LogoutButton/>
                </div>
                :
                <LanguageSelect/>
            }
        </header>
    )
}