import {SvgProps} from "../ui/inputs/inputsIcons";

export function LogoutIco(props:SvgProps){

    const fill = props.color || '#595959'
    return(
        <svg className={props.className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.72272 6.32563C8.04835 2.54412 9.9916 1 14.2458 1H14.3824C19.0777 1 20.958 2.88025 20.958 7.57563V14.4244C20.958 19.1197 19.0777 21 14.3824 21H14.2458C10.0231 21 8.07986 19.4769 7.73322 15.7584" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1302 10.9895H2.17645" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.51891 7.47046L1 10.9894L4.51891 14.5083" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function CloseCross(props: SvgProps) {
    const fill = props.color || '#595959'
    return (
        <svg className={props.className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L25 25" stroke={fill}/>
            <path d="M1 25L25 1" stroke={fill}/>
        </svg>
    )
}

export function WarningIco(props: SvgProps) {
    const fill = props.color || '#595959'
    return (
        <svg aria-hidden="true" className={props.className} fill="none"
             stroke={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
    )
}

export function FileIco(props:SvgProps){
    const fill = props.color || '#595959'
    return(
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 2.82051C2 2.07247 2.29716 1.35506 2.82611 0.826109C3.35506 0.29716 4.07247 0 4.82051 0H13.0123C13.2974 9.16511e-07 13.5784 0.0679134 13.832 0.198114C14.0856 0.328315 14.3046 0.517059 14.4708 0.748718L17.561 5.05333C17.7795 5.35897 17.8974 5.72513 17.8974 6.09949V17.1795C17.8974 17.9275 17.6003 18.6449 17.0713 19.1739C16.5424 19.7028 15.825 20 15.0769 20H4.82051C4.07247 20 3.35506 19.7028 2.82611 19.1739C2.29716 18.6449 2 17.9275 2 17.1795V2.82051ZM4.82051 1.53846C4.11282 1.53846 3.53846 2.11282 3.53846 2.82051V17.1795C3.53846 17.8872 4.11282 18.4615 4.82051 18.4615H15.0769C15.7846 18.4615 16.359 17.8872 16.359 17.1795V6.81744H13.0256C12.8216 6.81744 12.626 6.73639 12.4817 6.59213C12.3375 6.44787 12.2564 6.25222 12.2564 6.04821V1.53846H4.82051Z" fill={fill}/>
        </svg>
    )
}

export function ProjectIco(props:SvgProps){
    const fill = props.color || '#595959'
    return(
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M6.61763 4.9021C6.42262 4.9021 6.2356 4.97957 6.0977 5.11746C5.95981 5.25536 5.88234 5.44238 5.88234 5.63739C5.88234 5.83241 5.95981 6.01943 6.0977 6.15732C6.2356 6.29522 6.42262 6.37269 6.61763 6.37269H13.9706C14.1656 6.37269 14.3526 6.29522 14.4905 6.15732C14.6284 6.01943 14.7059 5.83241 14.7059 5.63739C14.7059 5.44238 14.6284 5.25536 14.4905 5.11746C14.3526 4.97957 14.1656 4.9021 13.9706 4.9021H6.61763ZM4.90195 9.55896C4.90195 9.36395 4.97942 9.17693 5.11731 9.03903C5.2552 8.90114 5.44223 8.82367 5.63724 8.82367H12.0098C12.2048 8.82367 12.3918 8.90114 12.5297 9.03903C12.6676 9.17693 12.7451 9.36395 12.7451 9.55896C12.7451 9.75397 12.6676 9.941 12.5297 10.0789C12.3918 10.2168 12.2048 10.2943 12.0098 10.2943H5.63724C5.44223 10.2943 5.2552 10.2168 5.11731 10.0789C4.97942 9.941 4.90195 9.75397 4.90195 9.55896ZM7.59803 12.7452C7.40301 12.7452 7.21599 12.8227 7.07809 12.9606C6.9402 13.0985 6.86273 13.2855 6.86273 13.4805C6.86273 13.6755 6.9402 13.8626 7.07809 14.0005C7.21599 14.1384 7.40301 14.2158 7.59803 14.2158H13.9706C14.1656 14.2158 14.3526 14.1384 14.4905 14.0005C14.6284 13.8626 14.7059 13.6755 14.7059 13.4805C14.7059 13.2855 14.6284 13.0985 14.4905 12.9606C14.3526 12.8227 14.1656 12.7452 13.9706 12.7452H7.59803Z" fill={fill}/>
                <path d="M0 1.75C0 0.784 0.784 0 1.75 0H18.25C19.216 0 20 0.784 20 1.75V18.25C20 18.7141 19.8156 19.1592 19.4874 19.4874C19.1592 19.8156 18.7141 20 18.25 20H1.75C1.28587 20 0.840752 19.8156 0.512563 19.4874C0.184375 19.1592 0 18.7141 0 18.25V1.75ZM1.75 1.5C1.6837 1.5 1.62011 1.52634 1.57322 1.57322C1.52634 1.62011 1.5 1.6837 1.5 1.75V18.25C1.5 18.388 1.612 18.5 1.75 18.5H18.25C18.3163 18.5 18.3799 18.4737 18.4268 18.4268C18.4737 18.3799 18.5 18.3163 18.5 18.25V1.75C18.5 1.6837 18.4737 1.62011 18.4268 1.57322C18.3799 1.52634 18.3163 1.5 18.25 1.5H1.75Z" fill={fill}/>
            </g>
        </svg>
    )
}

export function ButtonSpinner() {
    return (
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
    )
}


export function DeleteIco(props:SvgProps){
    const fill = props.color || '#595959'
    return(
        <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4451 5.09764C18.4265 5.09764 18.3986 5.09764 18.3707 5.09764C13.4498 4.60462 8.53821 4.41858 3.67308 4.9116L1.77542 5.09764C1.38472 5.13485 1.04054 4.85578 1.00333 4.46509C0.966119 4.07439 1.24519 3.73951 1.62658 3.7023L3.52425 3.51626C8.47309 3.01393 13.487 3.20928 18.5103 3.7023C18.8916 3.73951 19.1707 4.08369 19.1335 4.46509C19.1056 4.82788 18.7986 5.09764 18.4451 5.09764Z" fill={fill}/>
            <path d="M6.81827 4.15812C6.78106 4.15812 6.74385 4.15812 6.69734 4.14882C6.32524 4.0837 6.06478 3.72091 6.1299 3.34882L6.33455 2.13022C6.48338 1.2372 6.68803 0 8.85543 0H11.2926C13.4694 0 13.674 1.28371 13.8135 2.13952L14.0182 3.34882C14.0833 3.73022 13.8228 4.093 13.4508 4.14882C13.0694 4.21393 12.7066 3.95347 12.6508 3.58138L12.4461 2.37208C12.3159 1.56278 12.288 1.40464 11.3019 1.40464H8.86473C7.87873 1.40464 7.86012 1.53488 7.72059 2.36278L7.50663 3.57208C7.45082 3.91626 7.15315 4.15812 6.81827 4.15812Z" fill={fill}/>
            <path d="M13.0598 20H7.0877C3.8412 20 3.71097 18.2047 3.60864 16.7535L3.004 7.38608C2.97609 7.00468 3.27376 6.66981 3.65516 6.6419C4.04585 6.6233 4.37143 6.91167 4.39934 7.29306L5.00399 16.6605C5.10631 18.0744 5.14352 18.6047 7.0877 18.6047H13.0598C15.0133 18.6047 15.0505 18.0744 15.1435 16.6605L15.7481 7.29306C15.776 6.91167 16.1109 6.6233 16.4923 6.6419C16.8737 6.66981 17.1714 6.99538 17.1435 7.38608L16.5388 16.7535C16.4365 18.2047 16.3063 20 13.0598 20Z" fill={fill}/>
            <path d="M11.6176 14.8837H8.51999C8.13857 14.8837 7.8223 14.5674 7.8223 14.186C7.8223 13.8046 8.13857 13.4883 8.51999 13.4883H11.6176C11.999 13.4883 12.3153 13.8046 12.3153 14.186C12.3153 14.5674 11.999 14.8837 11.6176 14.8837Z" fill={fill}/>
            <path d="M12.3989 11.1627H7.74778C7.36639 11.1627 7.05011 10.8465 7.05011 10.4651C7.05011 10.0837 7.36639 9.7674 7.74778 9.7674H12.3989C12.7803 9.7674 13.0966 10.0837 13.0966 10.4651C13.0966 10.8465 12.7803 11.1627 12.3989 11.1627Z" fill={fill}/>
        </svg>
    )
}

export function EditIco(props:SvgProps){
    const fill = props.color || '#595959'
    return(
        <svg className={props.className} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.79627 16.9706C3.22413 16.9706 2.68952 16.7735 2.30497 16.4078C1.81725 15.9482 1.58277 15.2541 1.66718 14.5038L2.01421 11.4649C2.07987 10.8928 2.4269 10.1331 2.83021 9.72037L10.5306 1.56988C12.4533 -0.465416 14.4605 -0.521692 16.4957 1.40105C18.531 3.32379 18.5873 5.33094 16.6646 7.36623L8.96423 15.5167C8.5703 15.9388 7.83871 16.3327 7.26658 16.4266L4.24647 16.9424C4.08702 16.9518 3.94633 16.9706 3.79627 16.9706ZM13.5413 1.39167C12.8191 1.39167 12.1907 1.84187 11.5529 2.51718L3.85254 10.6771C3.66496 10.8741 3.44923 11.3431 3.41172 11.6151L3.06469 14.6538C3.02717 14.9634 3.1022 15.2166 3.27103 15.376C3.43986 15.5355 3.69309 15.5918 4.00261 15.5449L7.02272 15.0291C7.29472 14.9822 7.74492 14.7383 7.9325 14.5413L15.6329 6.3908C16.7959 5.15274 17.2179 4.00847 15.5203 2.41401C14.77 1.69181 14.1228 1.39167 13.5413 1.39167Z" fill={fill}/>
            <path d="M14.8641 8.93237C14.8454 8.93237 14.8172 8.93237 14.7985 8.93237C11.8722 8.64161 9.51797 6.41878 9.06777 3.51122C9.01149 3.12668 9.27411 2.77027 9.65866 2.70461C10.0432 2.64834 10.3996 2.91095 10.4653 3.2955C10.8217 5.56527 12.66 7.30981 14.9485 7.53491C15.3331 7.57243 15.6145 7.91946 15.577 8.30396C15.5301 8.66037 15.2205 8.93237 14.8641 8.93237Z" fill={fill}/>
            <path d="M18.2966 20H1.41395C1.0294 20 0.71051 19.6811 0.71051 19.2966C0.71051 18.912 1.0294 18.5931 1.41395 18.5931H18.2966C18.6811 18.5931 19 18.912 19 19.2966C19 19.6811 18.6811 20 18.2966 20Z" fill={fill}/>
        </svg>
    )
}
