import React, {useEffect, useState, useCallback, useRef} from "react"
import {IInput} from "./inputsModels";
import {Validation} from "./Validation";
import {InvalidIcon} from "./inputsIcons";
import {InputContainer} from "./InputContainer/InputContainer";
import {InputIconsContainer} from "./InputContainer/InputIconsContainer";

export function Input(props: IInput) {
    const inputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [validate,setValidate] = useState(props.validate)
    const [valid,setValid] = useState(Validation(props.value, validate))
    const [focus,setFocus] = useState(false)

    const validation = useCallback((value:string,validate?:string)=>{
        const validData = Validation(value, validate);
        props.updateValid && props.updateValid(validData)
        setValid(validData);
    },[props])

    useEffect(()=>{
        if(props.validate !== validate){
            validation(props.value,props.validate)
            setValidate(props.validate)
        }
    },[validate,props.value,props.validate,validation])

    useEffect(()=>{
        if(typeof props.valid === 'boolean' && props.valid !== valid){
            validation(props.value,props.validate)
        }
    },[props.value,props.validate,validation,props.valid,valid])


    const typeToInputType:{ [key: string]:any }  = {
        string:'text',
        float:'number',
        int:'number'
    }

    let inputType = props.type;
    if(props.type && typeToInputType[props.type]){
        inputType = typeToInputType[props.type]
    }

    const updateValue = (value:string)=>{
        props.updateValue && props.updateValue(value)
    }

    const autoResizeTextarea = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }

    useEffect(()=>{
        if(inputType === 'textarea'){
            autoResizeTextarea()
        }
    },[inputType])

    const changeStuff = (val:string) => {
        if(props.type === 'int'){
            updateValue(val.replace(/\D/g, ''))
        }else if(props.type === 'float'){
            updateValue(val.replace(/[^0-9,.]/g, ''))
        }else{
            updateValue(val)
        }
        props.onChange && props.onChange()
        if(inputType === 'textarea') autoResizeTextarea();
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = event.target.value
        changeStuff(val)
    }

    const onInputHandler = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
        changeStuff(val)
    }

    const focusHandler = () => {
        setFocus(true)
        props.onFocus && props.onFocus()
    }

    const blurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocus(false)
        validation(event.target.value, validate);
        props.onBlur && props.onBlur()
    }

    const autoComplete = props.autocomplete ? props.autocomplete : 'one-time-code'

    let inputClassName = "w-full pl-1 bg-transparent placeholder:italic placeholder:text-dark-gray";
    if(inputType === 'textarea'){
        inputClassName+= ' resize-none min-h-11 py-1.5'
    }else{
        inputClassName+= ' h-7';
    }
    inputClassName+= ' pr-1';
    /*
    if(inputType !== 'textarea' && !valid && !focus){
        inputClassName+= ' pr-7';
    }else{
        inputClassName+= ' pr-1';
    }
     */

    const inputProps = {
        className: inputClassName,
        type: inputType || 'text',
        name: props.name,
        value: props.value,
        id: 'inp_' + props.name,
        placeholder: props.placeholder,
        onFocus: focusHandler,
        onBlur: blurHandler,
        onChange: changeHandler,
        onInput: onInputHandler,
        autoComplete: autoComplete,
    };

    const InputElement = <>{inputType === 'textarea' ?
        <textarea {...inputProps} disabled={props.disabled} ref={textareaRef}/>
        :
        <input {...inputProps} disabled={props.disabled} ref={inputRef}/>
    }</>

    const inputFull = <>
        {InputElement}
        <InputIconsContainer>
            {(!valid && !focus) && <InvalidIcon
                className="w-5 -ml-6 mr-1"
                onClick={() => {
                    if(inputType === 'textarea'){
                        if (textareaRef.current) {
                            textareaRef.current.focus();
                        }
                    }else{
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }
                }}
            />}
        </InputIconsContainer>
    </>

    return (
        <>
            {inputType === 'hidden' ? InputElement :<div className={props.className}>
                <InputContainer
                    name={props.name}
                    label={props.label}
                    focus={focus}
                    valid={valid}
                    disabled={props.disabled}
                    shake={props.shake}
                >
                    {inputFull}
                </InputContainer>
            </div>}
        </>
    )
}