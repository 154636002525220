import i18n from "i18next";
import en from "./en.json"
import uk from "./uk.json";
import {initReactI18next} from "react-i18next";

export function initI18n(){

    const language = localStorage.getItem('language') || navigator.language.split(/[-_]/)[0];

    i18n
        .use(initReactI18next)
        .init({
            resources: {
                en: { translation: en },
                uk: { translation: uk },
            },
            lng: language,
            fallbackLng: "uk",
            interpolation: {
                escapeValue: false,
            },
        });

    return i18n;

}