import {PageContainer} from "../containers/PageContainer";
import {NotFoundPageContent} from "../components/ui/NotFoundPageContent";

export function NotFoundPage(){

    return(
        <PageContainer>
            <NotFoundPageContent/>
        </PageContainer>
    )
}