import React from "react";
import {Header} from "../components/ui/Header";
import {Footer} from "../components/ui/Footer";

interface PageContainerProps{
    children:React.ReactNode
}

export function PageContainer(props:PageContainerProps){
    return(
        <>
            <Header/>
            {props.children}
            <Footer/>
        </>
    )
}