import {useContext, useEffect, useState} from "react";
import {PageContainer} from "../containers/PageContainer";
import {useTranslation} from "react-i18next";
import {Api} from "../components/Api";
import {getLangText} from "../getLangText";
import {LocalizationContext} from "../context/LocalizationContext";
import {serverUrl} from "../components/admin/functions/serverUrl";
import {Button} from "../components/ui/Button";
import {useNavigate} from "react-router-dom";

export function HomePage(){
    const {t} = useTranslation();
    let navigate = useNavigate();
    const {language} = useContext(LocalizationContext)

    const [projects,setProjects] = useState<{[key:string]:any}[]>([])

    useEffect(()=>{
        Api({
            method:'get',
            url:'projects',
            data:{},
            callback:(data)=>{
                if(data.items){
                    setProjects(data.items)
                }
            }
        }).then()
    },[])

    const projectsItems = projects.map((item,index)=>{
        const linkTo = '/projects/'+item._id;
        return(
            <div
                key={index}
                className="flex flex-col bg-cover bg-top h-project-preview-small xl:h-project-preview justify-center relative group cursor-pointer"
                style={{backgroundImage:'url('+serverUrl(item.img)+')'}}
                onClick={()=>{navigate(linkTo)}}
            >
                <div className="group-hover:opacity-25 bg-dark-900/50 absolute inset-0 z-10"/>
                <h2 className="text-2xl font-medium relative z-20">{getLangText(item,language,'nameText')}</h2>
                <p className="text-lg mt-5 relative z-20">{getLangText(item,language,'shortAboutText')}</p>
                <Button className="mt-3 relative z-20" link={linkTo} buttonStyle="link" onClick={(e)=>{e.stopPropagation()}}>{t('mainPage.readMore')}</Button>
            </div>
        )
    })
    
    
    //<p>{t('mainPage.comment')}</p>

    return(
        <PageContainer>
            <div className="bg-yellow-bg flex items-bottom justify-center p-2">
                <img
                    className="block w-full h-full max-w-xl"
                    src={process.env.PUBLIC_URL+'/images/logo-big.svg'}
                    alt="Kotila"
                />
            </div>
            <section className="px-2 py-5 text-center">
                <h1 className="text-3xl">{t('mainPage.title')}</h1>
                <div className="py-5 min-h-96">
                    {projectsItems}
                </div>
            </section>
        </PageContainer>
    )
}