export function getInputClassName(className:string,valid:boolean,focus:boolean,disabled?:boolean,shake?:boolean,select?:boolean){
    let inputClassNames = [className]

    const validClassNames = select? "border-dark-900 bg-dark-900 text-white" : "border-gray-200 bg-gray-200 text-dark-black"
    const invalidClassNames =  select? "border-red-500 bg-red-100 text-red-500 td-border" : "border-red-500 bg-red-100 text-red-500"
    const disabledClassNames = "border-gray-300 bg-gray-100 text-dark-gray"

    inputClassNames = !valid && !focus ? [inputClassNames[0], invalidClassNames] : [inputClassNames[0],validClassNames]

    if(disabled) inputClassNames = [inputClassNames[0],disabledClassNames];
    if(shake && !select){
        inputClassNames = [inputClassNames[0],inputClassNames[1],'animation-shake']
    }

    return inputClassNames.join(' ')
}