import React from "react";
import {Link} from 'react-router-dom'
import {ButtonSpinner} from "../admin/AdminIcons";

interface IButton {
    type?:"button"|"submit"|"reset"
    loading?:boolean
    buttonStyle?:string
    name?:string
    link?:string
    onClick?:(event: any)=>void
    children?: React.ReactNode
    className?: string
    linked?: string
}

function HtmlButtonLink(props: IButton) {

    let type: "button" | "submit" | "reset" = "button"
    if (props.type) {
        type = props.type
    }
    return (
        <>
            {props.linked ?
                <a
                    className={props.className}
                    aria-label={props.name}
                    onClick={props.onClick}
                    href={props.linked}
                    target="_blank"
                    rel="noreferrer"
                >{props.children}</a>
                :
                <>
                    {props.loading ?
                        <button
                            className={props.className}
                            type={type}
                            aria-label={props.name}
                            onClick={props.onClick}
                            tabIndex={-1}
                            disabled
                        ><ButtonSpinner/>{props.children}</button>
                        :
                        <button
                            className={props.className}
                            type={type}
                            tabIndex={-1}
                            aria-label={props.name}
                            onClick={props.onClick}
                        >{props.children}</button>
                    }
                </>
            }
        </>
    )
}

export function Button(props: IButton) {
    let className = "text-center rounded-3 text-xs font-normal"
    if(props.buttonStyle!=='tile'){
        className+=" min-w-4 flex items-center justify-center leading-none"
    }else{
        className+=" flex justify-center flex-col items-center"
    }

    if (props.buttonStyle === 'green') {
        className += " px-4 text-white bg-green-bg min-h-8 h-10"
    }else if(props.buttonStyle === 'tile'){
        className += " p-4 text-white bg-green-bg min-h-8 h-24 w-32"
    }else if(props.buttonStyle === 'link'){
        className += " text-white underline"
    }

    if (props.className) {
        className += ' ' + props.className
    }

    let buttonContent = <>{props.children}</>;

    return (
        <>
            {props.link ?
                <Link
                    to={props.link}
                    className={className}
                    aria-label={props.name}
                    onClick={props.onClick}
                    tabIndex={-1}
                >{buttonContent}</Link>
                :
                <HtmlButtonLink
                    linked={props.linked}
                    className={className}
                    onClick={props.onClick}
                    loading={props.loading}
                    type={props.type}
                    name={props.name}
                >{buttonContent}</HtmlButtonLink>
            }
        </>
    )
}