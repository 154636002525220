import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {LocalizationState} from "./context/LocalizationContext";
import {initI18n} from "./i18n/initI18n";

initI18n();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <LocalizationState>
            <App />
        </LocalizationState>
    </BrowserRouter>
);
