import React from "react";
import {useNavigate} from "react-router-dom";
import {LogoutIco} from "./AdminIcons";

export function LogoutButton(){

    let navigate = useNavigate()

    const logout = ()=>{
        localStorage.setItem('authToken','')
        navigate('/Admin')
    }

    return(<button tabIndex={-1} aria-label="Вихід" onClick={logout} className="ml-5"><LogoutIco/></button>)
}