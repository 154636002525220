export interface SvgProps {
    className?: string
    onClick?: (event:any)=>void
    style?: object
    color?:string
}

export function ArrowDownIco(props:SvgProps){
    const fill = props.color || '#595959'
    return(
        <svg className={props.className} onClick={props.onClick} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 8L9.6188 -1.19209e-07L0.381198 -1.19209e-07L5 8ZM4.2 0V0.8H5.8V0L4.2 0Z" fill={fill}/>
        </svg>
    )
}

export function InvalidIcon(props: SvgProps){
    return(
        <svg className={props.className} onClick={props.onClick} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.59 3.85L10.65 0.42C9.67998 -0.14 8.47998 -0.14 7.49998 0.42L1.57 3.85C0.6 4.41 0 5.45 0 6.58V13.42C0 14.54 0.6 15.58 1.57 16.15L7.50998 19.58C8.47998 20.14 9.67998 20.14 10.66 19.58L16.6 16.15C17.57 15.59 18.17 14.55 18.17 13.42V6.58C18.16 5.45 17.56 4.42 16.59 3.85ZM8.32998 5.75C8.32998 5.34 8.66998 5 9.07998 5C9.48998 5 9.82998 5.34 9.82998 5.75V11C9.82998 11.41 9.48998 11.75 9.07998 11.75C8.66998 11.75 8.32998 11.41 8.32998 11V5.75ZM9.99998 14.63C9.94998 14.75 9.87998 14.86 9.78998 14.96C9.59998 15.15 9.34998 15.25 9.07998 15.25C8.94998 15.25 8.81998 15.22 8.69998 15.17C8.56998 15.12 8.46998 15.05 8.36998 14.96C8.27998 14.86 8.20998 14.75 8.14998 14.63C8.09998 14.51 8.07998 14.38 8.07998 14.25C8.07998 13.99 8.17998 13.73 8.36998 13.54C8.46998 13.45 8.56998 13.38 8.69998 13.33C9.06998 13.17 9.50998 13.26 9.78998 13.54C9.87998 13.64 9.94998 13.74 9.99998 13.87C10.05 13.99 10.08 14.12 10.08 14.25C10.08 14.38 10.05 14.51 9.99998 14.63Z" fill="#F17D7D"/>
        </svg>
    )
}