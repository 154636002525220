import {FileUploader} from "./FileUploader";
import {Button} from "../../ui/Button";
import {serverUrl} from "../functions/serverUrl";

interface AdminFileInputProps{
    type:string
    value:string
    label:string
    afterUploadCallback:(data:{[key:string]:any})=>void
    uploadText:string
    inContainer?:boolean
    manyFiles?:boolean
}

export function AdminFileInput(props:AdminFileInputProps){

    return(
        <div className={props.inContainer?'m-2':'m-2 w-full'}>
            <p className="text-xl">{props.label}</p>
            <div className="flex justify-between items-center">
                <FileUploader
                    type={props.type}
                    afterUploadCallback={props.afterUploadCallback}
                >
                    <Button className="mr-5" buttonStyle="green">{props.uploadText}</Button>
                </FileUploader>
                <div className="h-20 flex flex-shrink-0">
                    {(props.value && props.type==='image') && <img className="max-w-full max-h-full" src={serverUrl(props.value)} alt={props.label}/>}
                    {(props.value && props.type==='video') && <video
                        muted={true}
                        autoPlay={true}
                        loop={true}
                        className="max-w-full max-w-full"
                    >
                        <source
                            src={props.value}
                        />
                    </video>}
                </div>
            </div>
        </div>
    )
}