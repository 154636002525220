import React,{createContext, useState}  from "react";

interface IAdminMessageContext {
    setMessage: (str: string) => void
    message:string
    open:boolean
    setOpen:(flag:boolean)=>void
}

export const AdminMessageContext = createContext<IAdminMessageContext>({
    setMessage: () => {},
    message:'',
    open:false,
    setOpen:()=> {}
})

export const AdminMessageState = ({children}: { children: React.ReactNode }) => {

    const [open,setOpen] = useState(false)
    const [message,setMessage] = useState('')

    return (
        <AdminMessageContext.Provider value={{setMessage,message,open,setOpen}}>
            {children}
        </AdminMessageContext.Provider>
    )
}