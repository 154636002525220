import Validator from 'validatorjs';

export function Validation(value:string,validate?:string,trim?:boolean){

    let validateValue = value;
    if(trim) validateValue = validateValue.toString().trim();

    const rulesSplitter = '|'

    if(!validate){
        return true
    }else if((validate.split(rulesSplitter).includes('required')||validate.indexOf('required_if')>-1) && validateValue.length === 0) {
        return false
    }else{
        const rules: { [key: string]: any } = {};
        const attribute = 'field';
        validate.split(rulesSplitter).forEach(rule => {
            const indexOfColon = rule.indexOf(':');
            if (!rules[attribute]) {
                rules[attribute] = [];
            }
            if (indexOfColon !== -1) {

                const ruleName = rule.substring(0, indexOfColon).trim();
                const ruleValue = rule.substring(indexOfColon + 1).trim();

                if (ruleName !== 'required_if' && ruleName !== 'default') {
                    rules[attribute].push(ruleValue ? `${ruleName}:${ruleValue}` : ruleName);
                }
            }else{
                if (rule !== 'required_if' && rule !== 'default') {
                    rules[attribute].push(rule);
                }
            }
        });
        const validator = new Validator({ [attribute]: validateValue }, rules);
        const isValid = validator.passes();
        
        return isValid as boolean;
    }
}
