import {getFileType} from "../functions/getFileType";
import {Api} from "../../Api";

export class CustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise(async (resolve, reject) => {
                if(file){
                    const fileTypes = ['image/jpeg','image/png','image/webp','image/heic']
                    const fileType = await getFileType(file);
                    if(fileType && fileTypes.includes(fileType)){
                        const formData = new FormData();
                        formData.append('file', file);
                        Api({
                            method: 'post',
                            auth:true,
                            data:formData,
                            url: 'upload',
                            callback: (data)=>{

                                resolve({
                                    default: data.filePath // Повернення URL завантаженого зображення
                                });
                            }
                        }).then()
                    }
                }
            }));
    }

    // Опціонально, метод для скасування завантаження
    abort() {
        // Тут може бути логіка для скасування запиту до бекенду
    }
}