import React, {useContext} from "react";
import {CloseCross, WarningIco} from "../AdminIcons";
import {Button} from "../../ui/Button";
import {AdminMessageContext} from "../../../context/admin/AdminMessageContext";

interface AdminMessageProps{
    open?:boolean
    setOpen?:(flag:boolean)=>void
    message?:string
}

export function AdminMessage(props:AdminMessageProps){
    const {open,message,setOpen} = useContext(AdminMessageContext)

    const componentOpen = typeof props.open === 'boolean'? props.open:open
    const componentSetOpen = props.setOpen?props.setOpen:setOpen
    const componentMessage = props.message?props.message:message
    const closeModal = ()=>{componentSetOpen(false)}
    return(
        <>
            {componentOpen && <div className="flex fixed inset-0 z-40 bg-black-900/50 items-center justify-center text-black-900">
                <div className="bg-white p-5 relative w-96" onClick={(e)=>{e.stopPropagation()}}>
                    <button tabIndex={-1} aria-label="Закрити" onClick={closeModal} className="absolute right-2 top-2"><CloseCross/></button>
                    <WarningIco className="w-14 h-14 mx-auto"/>
                    <p className="text-center my-5">{componentMessage}</p>
                    <Button onClick={closeModal} className="mx-auto" buttonStyle="green">OK</Button>
                </div>
            </div>}
        </>
    )
}